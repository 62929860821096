@import 'variable.scss';

.contact {
    padding-top: 5vh;
    @include respond (small){
        width: 70%;
        padding-top: 40vh;
    }
    .contact-map-info{
        display: flex;
        gap: 5vw;
        @include respond (medium){
            flex-direction: column;
        }
    }
    h3 {
        font-size: xx-large;
        margin-bottom: 5vh;
        display: flex;
        justify-content: center;
        @include respond (large){
            font-size: x-large;
            padding: 0 2rem;
        }
        @include respond (medium){
            font-size: larger;
        }
    }
    h4 {
        font-size: large;
        margin: 1rem 0;
        @include respond (large){
            font-size: medium;
        }
        @include respond (medium){
            font-size: small;
        }
    }
    .contact-map{
        width: 45vw;
        @include respond (medium){
            width: 75vw;
        }
        @include respond (small){
            width: 62.5vw;
        }
    }
    .contact-info {
        margin-bottom: 1rem;
    }
    .contact-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        @include respond (small){
            flex-direction: column;
        }
        a{ 
            text-decoration: none;
            color: $background-color-red;
            &:after {
                background: none repeat scroll 0 0 transparent;
                content: "";
                display: block;
                height: 2px;
                background: $background-color-red;
                transition: width 0.3s ease 0s, left 0.3s ease 0s;
                width: 0;
              }
              &:hover:after { 
                width: 100%; 
                left: 0; 
              }
        }
    }
    .contact-adress{
        display: flex;
        justify-content: space-between;
        @include respond (small){
            flex-direction: column;
            align-items: center;
        }
    }
    button {
        margin-top: 1rem;
    }
}