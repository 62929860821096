.modal-majority {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(191, 179, 179, 0.88);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.modal-majority .modal-content {
  background: rgba(108, 2, 2, 0.9882352941);
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.64), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
  border-color: transparent;
  padding: 2rem 6rem;
  border-radius: 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .modal-majority .modal-content {
    padding: 1rem;
  }
}
@media (max-width: 425px) {
  .modal-majority .modal-content {
    margin: 2rem;
  }
}
.modal-majority .modal-content p {
  color: rgb(238, 237, 231);
  font-size: xx-large;
  padding: 1rem;
}
.modal-majority .modal-content button {
  margin: 2rem;
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(42, 9, 9, 0.51), -0.25rem -0.25rem 0.75rem rgba(255, 0, 0, 0.22);
  border-color: transparent;
  border-radius: 9px;
  padding: 1.5rem 3rem;
  background-color: rgba(108, 2, 2, 0.9882352941);
  color: rgb(238, 237, 231);
  font-size: larger;
  font-weight: 600;
  cursor: pointer;
}
.modal-majority .modal-content button:hover {
  box-shadow: inset 0.25rem 0.25rem 0.75rem rgba(42, 9, 9, 0.51), inset -0.25rem -0.25rem 0.75rem rgba(255, 0, 0, 0.22);
}
.modal-majority .blurred {
  filter: blur(5px);
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  display: none;
}

h3, h4, p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  font-family: "Libre Baskerville", serif;
}

p, a {
  font-family: "Lato";
}

html {
  scrollbar-width: none;
}

.close-modal {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.close-modal h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6d0707;
  width: 6rem;
  height: 3rem;
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.64), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
  border-radius: 8px;
  color: white;
  font-size: medium;
}
.close-modal h5:hover {
  box-shadow: inset 0.25rem 0.25rem 0.75rem rgba(42, 9, 9, 0.51), inset -0.25rem -0.25rem 0.75rem rgba(255, 0, 0, 0.22);
}
@media (max-width: 768px) {
  .close-modal h5 {
    font-size: small;
  }
}
@media (max-width: 425px) {
  .close-modal h5 {
    font-size: smaller;
  }
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.48); /* Fallback */
  -webkit-backdrop-filter: blur(5px); /* Safari */
  backdrop-filter: blur(5px); /* Autres navigateurs */
  background-color: rgba(0, 0, 0, 0.48);
  color: rgb(255, 255, 255);
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5);
}
header .left-side {
  display: flex;
  align-items: center;
}
header .right-side {
  display: flex;
  align-items: center;
}
header img, header .logo {
  width: 130px;
  height: 97.5px;
  filter: drop-shadow(0px 0px 0.025rem rgb(251, 255, 219));
}
@media (max-width: 768px) {
  header img, header .logo {
    width: 110px;
    height: 82.5px;
  }
}
@media (max-width: 425px) {
  header img, header .logo {
    width: 90px;
    height: 67.5px;
  }
}
@media (max-width: 375px) {
  header img, header .logo {
    width: 70px;
    height: 52.5px;
  }
}
header h1 {
  font-size: 4vmin;
  font-weight: 400;
  font-style: italic;
}
header button {
  min-width: 3.5rem;
  font-size: large;
  font-family: "Libre Baskerville";
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 0.3rem;
  padding: 1rem;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.5);
  color: rgb(251, 255, 219);
}
@media (max-width: 768px) {
  header button {
    min-width: 3rem;
    height: 3.5rem;
    padding: 0;
  }
}
@media (max-width: 768px) {
  header button {
    font-size: small;
    height: 3rem;
    min-width: none;
  }
}
@media (max-height: 720px) {
  header button {
    min-width: 3rem;
    height: 3rem;
    padding: 0;
  }
}
@media (max-height: 525px) {
  header button {
    font-size: small;
    height: 2rem;
  }
}
header button:hover {
  cursor: pointer;
  backdrop-filter: blur(0px);
  box-shadow: inset 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), inset -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.2);
}
@media (max-width: 425px) {
  header button .lang .header {
    margin-right: 2rem;
  }
}
header button svg {
  fill: white;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  header button svg {
    width: 80%;
    height: 80%;
  }
}
@media (max-width: 768px) {
  header button svg {
    width: 60%;
    height: 60%;
  }
}
@media (max-height: 720px) {
  header button svg {
    width: 80%;
    height: 80%;
  }
}
@media (max-height: 525px) {
  header button svg {
    width: 60%;
    height: 60%;
  }
}
header .menu {
  padding: 0 2rem 0 0;
}
header .menu .menu-svg, header .menu .close {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
}
@media (max-width: 768px) {
  header .menu .menu-svg, header .menu .close {
    font-size: 2.2rem;
    height: 3rem;
    width: none;
    padding: 0;
  }
}
@media (max-height: 720px) {
  header .menu .menu-svg, header .menu .close {
    width: 3rem;
    height: 3rem;
    padding: 0;
  }
}
@media (max-height: 525px) {
  header .menu .menu-svg, header .menu .close {
    font-size: small;
    height: 2rem;
  }
}
header .menu .hover {
  display: none;
}

.header {
  margin-right: 2rem;
}

.modal-menu {
  position: absolute;
  right: 2vmin;
  top: 0.2vmin;
  border: 2px solid;
  border-radius: 6px;
  border-color: transparent;
  background: rgba(29, 29, 29, 0.69);
  height: 19rem;
  width: 10rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 768px) {
  .modal-menu {
    top: 1vmin;
  }
}
@media (max-width: 425px) {
  .modal-menu {
    right: 3.5vmin;
    top: 1.6vmin;
  }
}
@media (max-width: 375px) {
  .modal-menu {
    right: 3vmin;
    top: 2vmin;
  }
}
@media (max-height: 720px) {
  .modal-menu {
    padding: 1.32vh 1.8vw;
  }
}
.modal-menu ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: x-large;
  gap: 1rem;
  list-style: none;
}
.modal-menu ul li a {
  color: rgb(238, 237, 231);
  text-decoration: none;
}
.modal-menu ul li a:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  display: block;
  height: 2px;
  background: rgb(238, 237, 231);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.modal-menu ul li a:hover:after {
  width: 100%;
  left: 0;
}

.scroll-indicator {
  height: 15dvh;
  max-width: 100%;
  width: 15dvw;
  position: absolute;
  bottom: 15dvh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  object-fit: contain;
}

.container {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100dvh;
  scrollbar-width: none;
}

.section {
  scroll-snap-align: start;
  height: 100dvh;
  overflow: hidden;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.section button {
  display: contents;
  cursor: pointer;
}

.content {
  height: 100vh;
}
.content img {
  height: 100%;
  width: 120%;
  margin-left: -10%;
  object-fit: cover;
  animation: zoom 10s infinite;
}

.section .content {
  position: relative;
}

.section .modal {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 248, 234, 0.76);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.video-player {
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-player video {
  object-fit: cover;
  width: 120%;
  margin-left: -10%;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
}
.grid .title-link {
  grid-area: 2/2/3/3;
  z-index: 1;
  text-align: center;
  font-size: 3rem;
}
.grid .content {
  grid-area: 1/1/4/4;
  z-index: 0;
}
.grid h2 {
  color: rgb(238, 237, 231);
  font-size: 5vmin;
  font-weight: 200;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.5);
  border-radius: 0.3rem;
  padding: 0.75rem;
}
.grid h2:hover {
  color: rgb(30, 31, 29);
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: inset 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), inset -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.2);
}
.grid a {
  text-decoration: none;
}

.last {
  height: 20vh;
}

.modal-wine .close-modal {
  margin: 0;
  gap: 50%;
}
.modal-wine .close-modal .buttonBuy {
  background-color: rgba(42, 185, 58, 0.89);
  font-size: large;
  font-style: italic;
  font-weight: lighter;
}

.wine {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 425px) {
  .wine {
    flex-direction: column;
  }
}

.section .modal {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.info {
  padding-right: 10vw;
  padding-left: 2vw;
  padding-top: 5vh;
  font-size: larger;
  height: 60vh;
  overflow-x: hidden;
}
@media (max-width: 425px) {
  .info {
    height: 25vh;
    padding-top: 0;
  }
}
.info .info-row {
  display: flex;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .info .info-row {
    margin-bottom: 1rem;
    flex-direction: column;
    font-size: smaller;
  }
}
.info .info-title {
  width: 9rem;
  font-weight: bold;
}
.info .info-value {
  flex: 1;
}
.info h3, .info h4 {
  color: rgba(108, 2, 2, 0.9882352941);
}
.info h3 {
  margin-bottom: 0.2rem;
  font-size: xx-large;
}
@media (max-width: 1024px) {
  .info h3 {
    font-size: x-large;
  }
}
@media (max-width: 768px) {
  .info h3 {
    font-size: medium;
  }
}
.info h4 {
  margin-bottom: 2rem;
  font-size: larger;
}
@media (max-width: 1024px) {
  .info h4 {
    font-size: medium;
  }
}
@media (max-width: 768px) {
  .info h4 {
    font-size: small;
  }
}

.slider {
  display: flex;
  align-items: center;
  margin: 0 -5vw;
  transition: height 0.5s ease;
  height: 63vh;
}
@media (max-width: 425px) {
  .slider {
    height: 43vh;
  }
}
.slider img {
  cursor: pointer;
  height: 60vh;
  width: 20vw;
  object-fit: cover;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.5);
  animation: none;
  transition: all 0.5s;
}
@media (max-width: 425px) {
  .slider img {
    height: 40vh;
  }
}
.slider img.reduced {
  height: 50vh;
  width: 9vw;
  border-radius: 6px;
}
@media (max-width: 768px) {
  .slider img.reduced {
    height: 40vh;
  }
}
@media (max-width: 425px) {
  .slider img.reduced {
    width: 20vw;
    height: 35vh;
  }
}
.slider img.reduced:nth-child(1), .slider img.reduced:nth-child(2), .slider img.reduced:nth-child(4), .slider img.reduced:nth-child(5) {
  filter: grayscale(10%) brightness(70%);
  opacity: 0.6;
  box-shadow: none;
}
.slider img:nth-child(3) {
  transform: scale(1);
  z-index: 2;
  filter: none;
}
.slider img:nth-child(2) {
  transform: scale(0.75) translateX(25%);
  z-index: 1;
}
.slider img:nth-child(4) {
  transform: scale(0.75) translateX(-25%);
  z-index: 1;
}
.slider img:nth-child(1) {
  transform: scale(0.5) translateX(115%);
  z-index: 0;
}
.slider img:nth-child(5) {
  transform: scale(0.5) translateX(-115%);
  z-index: 0;
}

.bottle-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.bottle-container img {
  width: 100%;
  display: block;
}

.bottle-container .hover-text {
  visibility: hidden;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bottle-container:hover .hover-text {
  visibility: visible;
}

.selectionWineNumber {
  width: 58%;
}
.selectionWineNumber .formTitle {
  color: #510000;
  font-size: 38px;
  background-color: rgba(255, 225, 187, 0.7411764706);
  margin: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.5);
  border-radius: 0.3rem;
  padding: 0.75rem;
}
.selectionWineNumber .formSelectionWine {
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgba(235, 225, 197, 0.42);
  border-radius: 5px;
}
.selectionWineNumber .formSelectionWine .inputNumberWine {
  width: 7.5rem;
  display: flex;
  border: 2px solid rgba(39, 5, 5, 0.3490196078);
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  background-color: aliceblue;
  flex-direction: row;
}
.selectionWineNumber .formSelectionWine .inputNumberWine .updown {
  width: 1rem;
  height: 1rem;
}
.selectionWineNumber .formSelectionWine .inputNumberWine .numberWine {
  border: none;
  background-color: aliceblue;
}
.selectionWineNumber .formSelectionWine .inputNumberWine .wineNumberControl {
  display: flex;
  width: auto;
  background-color: aliceblue;
  gap: 1rem;
}
.selectionWineNumber .formSelectionWine .infoAchat {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.selectionWineNumber .buttonContinue {
  background-color: rgba(42, 185, 58, 0.89);
  font-size: large;
  font-style: italic;
  font-weight: lighter;
  display: block;
  width: 6rem;
  height: 3rem;
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.64), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
  border-radius: 8px;
  border-color: transparent;
  color: white;
  font-size: medium;
}

.formClient {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formClient .formTitle {
  color: #510000;
  font-size: 38px;
  background-color: rgba(255, 225, 187, 0.7411764706);
  margin: 10px;
}
.formClient .informationsClient {
  margin: 10px;
  padding: 10px;
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
}
.formClient .buttonToStripe {
  cursor: pointer;
  display: block;
  height: 40px;
  width: 180px;
  font-style: italic;
  font-weight: lighter;
  background-color: rgba(42, 185, 58, 0.89);
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.39), -0.25rem -0.25rem 0.75rem rgba(251, 251, 251, 0.88);
  color: white;
  border-radius: 5px;
  border-color: transparent;
  font-size: large;
}

.modal form {
  height: 500px;
  background: rgba(235, 225, 197, 0.42);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoClientLabel {
  display: flex;
  flex-direction: column;
  width: 180px;
}

.modal .closeButton {
  cursor: pointer;
  display: block;
  background-color: #6d0707;
  width: 6rem;
  height: 3rem;
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.64), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
  border-radius: 8px;
  border-color: transparent;
  color: white;
  font-size: medium;
}

.modal form, .formStripe {
  width: 50%;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  border-radius: 1rem;
  border: 1rem solid rgba(211, 195, 151, 0.2117647059);
  margin: 1rem;
}
.modal form .buttonToStripe, .formStripe .buttonToStripe {
  cursor: pointer;
  display: block;
  height: 40px;
  width: 180px;
  font-style: italic;
  font-weight: lighter;
  background-color: rgba(42, 185, 58, 0.89);
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.39), -0.25rem -0.25rem 0.75rem rgba(251, 251, 251, 0.88);
  color: white;
  border-radius: 5px;
  border-color: transparent;
  font-size: large;
}
.modal form .containerButtonStripe, .formStripe .containerButtonStripe {
  display: flex;
  flex-direction: row-reverse;
}

.paymentSuccess {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
  background-color: #fff9e5;
  padding: 1rem;
  border-radius: 1rem;
  border: solid rgba(203, 161, 29, 0.9215686275) 0.2rem;
}
.paymentSuccess h3 {
  font-size: x-large;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
.paymentSuccess p {
  font-size: larger;
}

.history {
  padding: 6rem 20vw;
  height: 140vh;
  overflow: scroll;
}
@media (max-width: 1024px) {
  .history {
    font-size: large;
  }
}
@media (max-width: 768px) {
  .history {
    font-size: medium;
    padding: 6rem 10vw;
  }
}
.history h3 {
  font-size: xxx-large;
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .history h3 {
    font-size: xx-large;
  }
}
.history h4 {
  margin: 4rem 0 1rem 0;
}

.Event, .News {
  padding: 2rem 20vw 0;
  font-size: x-large;
}
.Event h3, .News h3 {
  font-size: xxx-large;
  display: flex;
  justify-content: center;
  margin: 4rem 0;
}
@media (max-width: 375px) {
  .Event h3, .News h3 {
    font-size: xx-large;
  }
}
.Event h4, .News h4 {
  margin: 4rem 0 1rem 0;
}
.Event p, .News p {
  margin-bottom: 2rem;
}
.Event .social-link, .News .social-link {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 375px) {
  .Event .social-link, .News .social-link {
    gap: 2rem;
  }
}
.Event .social-link .fa-brands, .News .social-link .fa-brands {
  font-weight: 400;
  font-size: xxx-large;
  margin: 20px;
}
@media (max-width: 375px) {
  .Event .social-link .fa-brands, .News .social-link .fa-brands {
    font-size: xx-large;
  }
}
.Event .social-link a, .News .social-link a {
  text-decoration: none;
  color: rgb(30, 31, 29);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Event .social-link a:hover, .News .social-link a:hover {
  color: rgba(108, 2, 2, 0.9882352941);
}

.contact {
  padding-top: 5vh;
}
@media (max-width: 425px) {
  .contact {
    width: 70%;
    padding-top: 40vh;
  }
}
.contact .contact-map-info {
  display: flex;
  gap: 5vw;
}
@media (max-width: 768px) {
  .contact .contact-map-info {
    flex-direction: column;
  }
}
.contact h3 {
  font-size: xx-large;
  margin-bottom: 5vh;
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .contact h3 {
    font-size: x-large;
    padding: 0 2rem;
  }
}
@media (max-width: 768px) {
  .contact h3 {
    font-size: larger;
  }
}
.contact h4 {
  font-size: large;
  margin: 1rem 0;
}
@media (max-width: 1024px) {
  .contact h4 {
    font-size: medium;
  }
}
@media (max-width: 768px) {
  .contact h4 {
    font-size: small;
  }
}
.contact .contact-map {
  width: 45vw;
}
@media (max-width: 768px) {
  .contact .contact-map {
    width: 75vw;
  }
}
@media (max-width: 425px) {
  .contact .contact-map {
    width: 62.5vw;
  }
}
.contact .contact-info {
  margin-bottom: 1rem;
}
.contact .contact-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
@media (max-width: 425px) {
  .contact .contact-link {
    flex-direction: column;
  }
}
.contact .contact-link a {
  text-decoration: none;
  color: rgba(108, 2, 2, 0.9882352941);
}
.contact .contact-link a:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  display: block;
  height: 2px;
  background: rgba(108, 2, 2, 0.9882352941);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.contact .contact-link a:hover:after {
  width: 100%;
  left: 0;
}
.contact .contact-adress {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 425px) {
  .contact .contact-adress {
    flex-direction: column;
    align-items: center;
  }
}
.contact button {
  margin-top: 1rem;
}

.maps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 45vh;
  border-radius: 0.3rem;
  border-color: transparent;
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.5);
}
@media (max-width: 1024px) {
  .maps {
    height: 35vh;
  }
}
@media (max-width: 768px) {
  .maps {
    height: 30vh;
  }
}

footer {
  color: rgb(238, 237, 231);
  background-color: rgb(75, 8, 8);
  width: 100%;
  padding: 8vh;
  margin-top: auto;
  box-shadow: inset 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), inset -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.2);
}
footer .footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 425px) {
  footer .footer {
    flex-direction: column-reverse;
    gap: 2vh;
  }
}
footer .footer a {
  font-size: 3vmin;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}
footer .footer a:hover {
  color: #ffd780;
}
footer .footer a i {
  font-size: 5vmin;
  padding-right: 2vmin;
}

