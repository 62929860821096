@import 'mixin.scss';

.maps {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 100%;
    height: 45vh;
    border-radius: 0.3rem;
    border-color: transparent ;
    box-shadow: 0.25rem 0.25rem 0.75rem rgb(0, 0, 0, 0.5),
         -0.25rem -0.25rem 0.75rem rgb(255, 255, 255, 0.5);
    @include respond (large){
        height: 35vh;
    }
    @include respond (medium) {
        height: 30vh;
            
    }
}