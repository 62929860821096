
header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12vh;
    display: flex;
        justify-content: space-between;
        align-items: center;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.48); /* Fallback */
    -webkit-backdrop-filter: blur(5px); /* Safari */
    backdrop-filter: blur(5px); /* Autres navigateurs */
    background-color: rgb(0 0 0 / 48%);
    color: rgb(255, 255, 255);
    box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5);
    .left-side{
        display: flex;
        align-items: center;
    }
    .right-side{
        display: flex;
        align-items: center;
    }
    img, .logo{
        width: 130px;
        height: 97.5px;
        filter: drop-shadow(0px 0px 0.025rem rgb(251, 255, 219));
        @include respond (medium){
            width: 110px;
            height: 82.5px;
        }
        @include respond (small){
            width: 90px;
            height: 67.5px;
        }
        @include respond (smaller){
            width: 70px;
            height: 52.5px;
        }
    }
    h1{
        font-size: 4vmin;
        font-weight: 400;
        font-style: italic;

    }
    button {
        min-width: 3.5rem;
        font-size: large;
        font-family: 'Libre Baskerville';
        font-weight: 400;
        text-transform: uppercase;
        border-radius: 0.3rem;
        padding: 1rem;
        border-color: transparent;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px); 
        box-shadow: 0.25rem 0.25rem 0.75rem rgb(0, 0, 0, 0.5),
         -0.25rem -0.25rem 0.75rem rgb(255, 255, 255, 0.5);
        color: rgb(251, 255, 219);
        @include respond (medium){
            min-width: 3rem;
            height: 3.5rem;
            padding: 0;
        }
        @include respond (medium){
            font-size: small;
            height: 3rem;
            min-width: none;           
        }
        @include respond (hmedium){
            min-width: 3rem;
            height: 3rem;
            padding: 0;
        }
        @include respond (hsmall){
            font-size: small;
            height: 2rem;
        }
        &:hover {
            cursor: pointer;
            backdrop-filter: blur(0px);
            box-shadow: inset 0.25rem 0.25rem 0.75rem rgb(0, 0, 0, 0.5),
            inset -0.25rem -0.25rem 0.75rem rgb(255, 255, 255, 0.2);
        }
        .lang {
            .header{
                @include respond (small){
                    margin-right: 2rem;
                }
        }
        }
        svg{
            fill: white;
            width: 100%;
            height: 100%;
            @include respond (medium){
                width: 80%;
                height: 80%;
            }
            @include respond (medium){
                width: 60%;
                height: 60%;             
            }
            @include respond (hmedium){
                width: 80%;
                height: 80%;
            }
            @include respond (hsmall){
                width: 60%;
                height: 60%;  
            }
        }

    }
    .menu{
        padding: 0 2rem 0 0;
        .menu-svg, .close{
            width: 3.5rem;
            height: 3.5rem;
            padding: 0.5rem;   
            @include respond (medium){
                font-size: 2.2rem;
                height: 3rem;
                width: none; 
                padding: 0; 
            }
            @include respond (hmedium){
                width: 3rem;
                height: 3rem;
                padding: 0;
            }
            @include respond (hsmall){
                font-size: small;
                height: 2rem;
            }
        }
        .hover{
            display: none;
        }
    }

}
.header {
    margin-right: 2rem;   
}