@mixin respond($breakpoint) {
    @if $breakpoint == large {
      @media (max-width: 1024px) { @content;}
    }
    @if $breakpoint == medium {
      @media (max-width: 768px) { @content; }
    }
    @if $breakpoint == small {
      @media (max-width: 425px) { @content; }
    }
    @if $breakpoint == smaller {
      @media (max-width: 375px) { @content; }
    }
    @if $breakpoint == hlarge {
      @media (max-height: 921px) { @content;}
    }
    @if $breakpoint == hmedium {
      @media (max-height: 720px) { @content; }
    }
    @if $breakpoint == hsmall {
      @media (max-height: 525px) { @content; }
    }
    @if $breakpoint == hsmaller {
      @media (max-height: 400px) { @content; }
    }
  }

   