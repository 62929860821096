.slider {
    display: flex;
    align-items: center;
    margin: 0 -5vw;
    transition: height 0.5s ease;
    height: 63vh;
    @include respond (small){
        height: 43vh;
    }
    
    img {

        cursor: pointer;
        height: 60vh;
        width: 20vw;
        object-fit: cover;
        margin: 0;
        border-radius: 4px;
        box-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.5), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.5);
        animation: none;
        transition: all 0.5s;
        @include respond (small){
            height: 40vh;
        }
        &.reduced {
            height: 50vh;
            width: 9vw;
            border-radius: 6px;
            @include respond (medium){
                height: 40vh;
            }
            @include respond (small){
                width: 20vw;
                height: 35vh;
            }
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
                filter: grayscale(10%) brightness(70%);
                opacity: 0.6;
                box-shadow: none;
            }
        }

    }


    img:nth-child(3) {
        transform: scale(1);
        z-index: 2;
        filter: none; 
    }

    img:nth-child(2) {
        transform: scale(0.75) translateX(25%);
        z-index: 1;
    }

    img:nth-child(4) {
        transform: scale(0.75) translateX(-25%);
        z-index: 1;
    }

    img:nth-child(1) {
        transform: scale(0.5) translateX(115%);
        z-index: 0;
    }

    img:nth-child(5) {
        transform: scale(0.5) translateX(-115%);
        z-index: 0;
    }
}
.bottle-container {
    position: relative;
    display: inline-block;
    max-width: 100%;
  }
  
  .bottle-container img {
    width: 100%;
    display: block;
  }
  
  .bottle-container .hover-text {
    visibility: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .bottle-container:hover .hover-text {
    visibility: visible;
  }
  