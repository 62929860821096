@import 'variable.scss';

.scroll-indicator {
    height: 15dvh;
    max-width: 100%;
    width: 15dvw;
    position: absolute;
    bottom: 15dvh; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 1; 
    object-fit: contain;
}   



.container {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    overflow-y: scroll;
    height: 100dvh;
    scrollbar-width: none;
}
  
.section {
  scroll-snap-align: start;
  height: 100dvh;
  overflow: hidden;
  
  @keyframes zoom {
      0% {
          transform: scale(1);
      }
      50% {
          transform: scale(1.05);
      }
      100% {
          transform: scale(1);
      }
  }
  button {
    display: contents;
    cursor: pointer;
  }
}
.content {
    height: 100vh;
    img {
        height: 100%;
        width: 120%;
        margin-left: -10%;
        object-fit: cover;
        animation: zoom 10s infinite;
    }
}

.section .content {
    position: relative;
}

.section .modal {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $background-color-sepia;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
}

.video-player {
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video {
      object-fit: cover;
      width: 120%;
      margin-left: -10%;
      height: 100%;
    }
}


.grid {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 0px 0px; 
    .title-link {
        grid-area: 2 / 2 / 3 / 3;
        z-index: 1;
        text-align: center;
        font-size: 3rem;
        
    }
  
    .content{
        grid-area: 1 / 1 / 4 / 4;
        z-index: 0;
    }
    h2 {
        color: $color-white;
        font-size: 5vmin;
        font-weight: 200;
        background-color: rgba(0,0,0, 0.2);
        backdrop-filter: blur(10px);
        box-shadow: 0.25rem 0.25rem 0.75rem rgb(0, 0, 0, 0.5),
         -0.25rem -0.25rem 0.75rem rgb(255, 255, 255, 0.5);
        border-radius: 0.3rem;
        padding: 0.75rem;
        &:hover {
            color: $color-black;
            background-color: rgb(255, 255, 255, 0.2);
            box-shadow: inset 0.25rem 0.25rem 0.75rem rgb(0, 0, 0, 0.5),
            inset -0.25rem -0.25rem 0.75rem rgb(255, 255, 255, 0.2);
        }
    }
    a {
        text-decoration: none;
    }
}

.last {
    height: 20vh;
}