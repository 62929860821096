.history{
    padding: 6rem 20vw;
    height: 140vh;
    overflow: scroll;
    @include respond (large){
        font-size: large;
    }
    @include respond (medium){
        font-size: medium;
        padding: 6rem 10vw;
    }
    h3{
        font-size: xxx-large;
        display: flex;
        justify-content: center;
        @include respond (large){
            font-size: xx-large;
        }
    }
    h4{
        margin: 4rem 0 1rem 0;
    }
}
