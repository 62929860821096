@import 'variable.scss';

footer {
    color: $color-white;
    background-color: $background-color-dark-red;
    width: 100%;
    padding: 8vh;
    margin-top: auto;
    box-shadow: inset 0.25rem 0.25rem 0.75rem rgb(0, 0, 0, 0.5),
            inset -0.25rem -0.25rem 0.75rem rgb(255, 255, 255, 0.2);
    .footer{
        display: flex;
            justify-content: space-around;
            align-items: center;
            @include respond (small){
                flex-direction: column-reverse;
                gap: 2vh;
            }
        a{
            font-size: 3vmin;
            text-decoration: none;
            color: white;
            display: flex;
                align-items: center;
                &:hover{
                    color: #ffd780;
                }
            i{
                font-size: 5vmin;
                padding-right: 2vmin;
            }
        }
    }
}