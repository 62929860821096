@import 'variable.scss';

.Event, .News{
    padding: 2rem 20vw 0;
    font-size: x-large;
    h3{
        font-size: xxx-large;
        display: flex;
        justify-content: center;
        margin: 4rem 0;
        @include respond (smaller){
            font-size: xx-large;
        }
    }
    h4{
        margin: 4rem 0 1rem 0;
    }
    p{
        margin-bottom: 2rem;
    }
    .social-link{
        display: flex;
        justify-content: space-around;
        @include respond (smaller){
            gap: 2rem;
        }
        .fa-brands{
            font-weight: 400;
            font-size: xxx-large;
            margin: 20px;
            @include respond (smaller){
                font-size: xx-large;
            }
        }
        a{
            text-decoration: none;
            color: $color-black;
            display: flex;
                flex-direction: column;
                align-items: center;
            &:hover{
                color: $background-color-red;
            }
        }
    }

}