
.modal-majority{
    position: fixed;
        top: 0;
        left: 0;
    width: 100%;
    height: 100%;
    background: $background-color-grey;
    display: flex;
        justify-content: center;
        align-items: center;
    z-index: 2000; 
    .modal-content {
        background: $background-color-red;
        box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 64%), -0.25rem -0.25rem 0.75rem rgba(255, 255, 255, 0.81);
        border-color: transparent;
        padding: 2rem 6rem;
        border-radius: 20px;
        text-align: center;
        @include respond (medium){
            padding: 1rem;
        }
        @include respond (small){
            margin: 2rem;
        }
        p{
            color: $color-white;
            font-size: xx-large;
            padding: 1rem;
        }
        button{
            margin: 2rem;
            box-shadow: 0.25rem 0.25rem 0.75rem rgb(42 9 9 / 51%), -0.25rem -0.25rem 0.75rem rgb(255 0 0 / 22%);
            border-color: transparent;
            border-radius: 9px;
            padding: 1.5rem 3rem;
            background-color: $background-color-red;
            color: $color-white;
            font-size: larger;
            font-weight: 600;
            cursor: pointer;
            &:hover{
                box-shadow: inset 0.25rem 0.25rem 0.75rem rgb(42 9 9 / 51%), inset -0.25rem -0.25rem 0.75rem rgb(255 0 0 / 22%);
            }
        }
    }
    .blurred {
        filter: blur(5px);
    }
    
}
